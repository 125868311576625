import { getColorBrightness } from "@/components/whiteLabel/whiteLabel.helper";
import { DEFAULT_THEME } from "@/components/whiteLabel/whiteLabel.settings";
import { BACKGROUND_COLOR_TYPE, THSL, TTheme } from "@/components/whiteLabel/whiteLabel.types";

function getPrimaryColors(hsl: THSL, textHSL: THSL): string {
    // Background brightness threshold to switch between black/white text color
    const COLOR_BRIGHTNESS_THRESHOLD = 128;

    const getTextLightness = (lightness: number) => {
        const brightness = getColorBrightness({ ...hsl, lightness });
        return brightness >= COLOR_BRIGHTNESS_THRESHOLD ? 0 : 100;
    };

    const primaryTextLightness = getTextLightness(hsl.lightness);
    const primaryTextUnusedLightness = getTextLightness(hsl.lightness + 5);
    const primaryTextDisabledLightness = getTextLightness(hsl.lightness + 10);

    return `
        --primary: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%);
        --primary--hover: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness - 5}%);
        --primary--active: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness - 10}%);
        --primary--unused: hsla(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%, 0.8);
        --primary--disabled: hsla(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%, 0.6);
        --primary-text: hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextLightness}%);
        --primary-text--unused: hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextUnusedLightness}%);
        --primary-text--disabled: hsl(${textHSL.hue}, ${textHSL.saturation}%, ${primaryTextDisabledLightness}%);
        `;
}

function getSidebarColors(hsl: THSL, textHSL: THSL): string {
    // Sidebar color lightness threshold to switch between black/white text color
    const LIGHTNESS_THRESHOLD = 128;

    const getIconColor = (lightness: number) => {
        const brightness = getColorBrightness({ ...hsl, lightness });
        return brightness >= LIGHTNESS_THRESHOLD ? 0 : 100;
    };

    const sidebarIcon = getIconColor(hsl.lightness + 5);

    return `
        --sidebar: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%);
        --sidebar-icon: hsl(${hsl.hue}, ${hsl.saturation}%, ${sidebarIcon}%);
        --sidebar-hover: hsl(${hsl.hue}, ${hsl.saturation}%, 25%);
        `;
}

function getTextColors(hsl: THSL, backgroundHSL: THSL): string {
    const COLOR_LIGHTNESS = {
        BACKGROUND_LIGHTNESS_100_70: { L1: 15, L2: 30, L3: 45 },
        BACKGROUND_LIGHTNESS_70_55: { L1: 10, L2: 20, L3: 30 },
        BACKGROUND_LIGHTNESS_55_45: { L1: 10, L2: 18, L3: 24 },
        BACKGROUND_LIGHTNESS_45_0: { L1: 12, L2: 23, L3: 33 },
    };

    let lightnessL1;
    let lightnessL2;
    let lightnessL3;

    if (backgroundHSL.lightness > 70) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_100_70.L3;
    } else if (backgroundHSL.lightness <= 70 && backgroundHSL.lightness > 55) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_70_55.L3;
    } else if (backgroundHSL.lightness <= 55 && backgroundHSL.lightness > 45) {
        lightnessL1 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L1;
        lightnessL2 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L2;
        lightnessL3 = hsl.lightness + COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_55_45.L3;
    } else {
        lightnessL1 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L1;
        lightnessL2 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L2;
        lightnessL3 = hsl.lightness - COLOR_LIGHTNESS.BACKGROUND_LIGHTNESS_45_0.L3;
    }

    return `
        --text: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%);
        --text--l1: hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL1}%);
        --text--l2: hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL2}%);
        --text--l3: hsl(${hsl.hue}, ${hsl.saturation}%, ${lightnessL3}%);
        `;
}

function getBackgroundColors(hsl: THSL, primaryColor: THSL): string {
    // Background lightness threshold to switch between dark/light themes
    const BACKGROUND_LIGHTNESS_THRESHOLD = 13;
    const BACKGROUND_LIGHTNESS = {
        DARK_THEME: { HOVER: 7, ACTIVE: 9, SECONDARY: 10, SPACER: 13 },
        LIGHT_THEME: { HOVER: 4, ACTIVE: 6, SECONDARY: 7, SPACER: 10 },
    };
    const getLightness = (lightness: number, type: BACKGROUND_COLOR_TYPE): number => {
        return lightness <= BACKGROUND_LIGHTNESS_THRESHOLD
            ? lightness + BACKGROUND_LIGHTNESS.DARK_THEME[type]
            : lightness - BACKGROUND_LIGHTNESS.LIGHT_THEME[type];
    };

    const activeLightness = getLightness(hsl.lightness, "ACTIVE");
    const hoverLightness = getLightness(hsl.lightness, "HOVER");
    const secondaryLightness = getLightness(hsl.lightness, "SECONDARY");
    const spacerLightness = getLightness(hsl.lightness, "SPACER");
    const highlightLightness = hsl.lightness > BACKGROUND_LIGHTNESS_THRESHOLD ? hsl.lightness - 1 : hsl.lightness + 2;

    // Shadow lightness threshold to switch between dark/light shadows
    const SHADOW_LIGHTNESS_THRESHOLD = 56;
    const shadowLightness = hsl.lightness < SHADOW_LIGHTNESS_THRESHOLD ? 100 : 12;

    // Background saturation threshold to adjust spacer saturation
    const SPACER_SATURATION_THRESHOLD = 40;
    const spacerSaturation = hsl.saturation >= SPACER_SATURATION_THRESHOLD ? hsl.saturation - 10 : hsl.saturation;

    return `
        --background: hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%);
        --background--hover: hsl(${primaryColor.hue}, ${primaryColor.saturation}%, ${hoverLightness}%);
        --background--active: hsl(${primaryColor.hue}, ${primaryColor.saturation}%, ${activeLightness}%);
        --background--secondary: hsl(${hsl.hue}, ${hsl.saturation}%, ${secondaryLightness}%);
        --background--spacer: hsl(${hsl.hue}, ${spacerSaturation}%, ${spacerLightness}%);
        --background--highlight: hsl(${hsl.hue}, ${hsl.saturation}%, ${highlightLightness}%);
        --background--opacity: hsla(${hsl.hue}, ${hsl.saturation}%, ${secondaryLightness}%, 0.5);
        --background--shadow: hsla(${hsl.hue}, ${hsl.saturation}%, ${shadowLightness}%, 0.15);
        `;
}

function getCssPropertiesByThemeKey(key: string, theme: TTheme): string {
    switch (key) {
        case "backgroundColor":
            return getBackgroundColors(theme.backgroundColor, theme.primaryColor);
        case "textColor":
            return getTextColors(theme.textColor, theme.backgroundColor);
        case "primaryColor":
            return getPrimaryColors(theme.primaryColor, theme.textColor);
        case "sidebarColor":
            return getSidebarColors(theme.sidebarColor, theme.textColor);
        case "logotype":
            return `--logotype: url(${theme.logotype});`;
        default:
            return "";
    }
}

function getCSSProperties(theme: TTheme): string {
    return Object.keys(theme).reduce((acc, key) => {
        const properties = getCssPropertiesByThemeKey(key, theme);
        acc += properties;
        return acc;
    }, "");
}

export function setTheme(theme: TTheme): void {
    document.documentElement.style.cssText = getCSSProperties(theme);
}

export async function whiteLabelHandler(url: string): Promise<TTheme> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw response;
        }
        const theme = (await response.json()) as TTheme;
        setTheme(theme);
        return theme;
    } catch (e) {
        setTheme(DEFAULT_THEME);
        // TODO: make some notification about error to get theme (use Sentry?)
        console.log(e);
        return DEFAULT_THEME;
    }
}

export function getThemeName(): string {
    const url = new URL(window.location.href);
    // TODO: refactor when backend implemented
    const theme = url.searchParams.get("theme");
    return theme ? `${theme}.mock.json` : null;
}
